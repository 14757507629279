<template>
  <div class="">
    <b-carousel
    id="carousel-no-animation"
    style="text-shadow: 0px 0px 2px #000"
    no-animation
    indicators
    img-height="850"
      >
    <b-carousel-slide v-for="(slide,index) in slides" :key="index" img-blank>
      <div class="slide-title" v-html="slide.title"></div>
      <div class="slide-sub-title mb-3" v-html="slide.text"></div>
      <img class="slide-img" :src="slide.image" />
        <!-- <b-img :src="slide.image"  /> -->
  </b-carousel-slide>
  </b-carousel>
  </div>
</template>

<script>
import { BCarousel, BCarouselSlide, BContainer ,BImg} from 'bootstrap-vue';
export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BContainer,
    BImg
  },
  data() {
    return {
      slides: [
        {
          title: `<div class="mb-1">Transform document management <br>and signatures with our streamlined <br>collaborative platform.</div>`,
          text: "<span>Our platform empowers teams to securely manage, sign, and track documents from anywhere, ensuring compliance, convenience, and productivity.</span>",
          image: require("@/assets/images/app-tour/ele-collab.png"),
        },
        {
          title: `<div class="mb-1">Welcome to CollabDocs<br>Simplify Your Workflow from <br>Start to Finish</div>`,
          text: "<span>At CollabDocs, we empower service providers with a seamless platform to manage every stage of the client journey from proposals to final document delivery and payment. Streamline your processes, enhance communication, and ensure secure transactions, all in one place.</span>",
          image: require("@/assets/images/app-tour/image-2.png"),
        },
        {
          title: `<div class="mb-1">How CollabDocs Works</div>`,
          text: `<div><div class="mb-1"><span class="font-aeonik-bold">Effortless Proposal Management</span> Create, send, and track proposals with ease. Customize templates, collect feedback, and secure e-signatures in one place.</div>  
                  <div class="mb-1"><span class="font-aeonik-bold">Simplified Document Delivery</span> Instantly send contracts, invoices, and reports. Files are automatically released after payment confirmation, ensuring security.</div>   
                  <div class="mb-1"><span class="font-aeonik-bold">Integrated Payments</span> Process payments securely, automate file delivery upon approval, and sync financial data with QuickBooks.</div>   
                  <div class="mb-1"><span class="font-aeonik-bold">Complete Workflow Tracking</span> Get real-time updates on proposals, payments, and document status, keeping everything organized in one platform.</div></div>`,
          image: require("@/assets/images/app-tour/image-3.png"),
        },
        {
          title: `<div class="mb-1">Why Choose CollabDocs?</div>`,
          text: `<div><div class="mb-1"><span class="font-aeonik-bold">All-in-One Solution</span> Manage proposals, payments, and document delivery in one platform.</div>  
                  <div class="mb-1"><span class="font-aeonik-bold">Maximized Efficiency</span> Automate tasks, reduce manual work, and streamline workflows.</div>  
                  <div class="mb-1"><span class="font-aeonik-bold">Guaranteed Payment</span> Deliver documents only after payment is processed.</div>  
                  <div class="mb-1"><span class="font-aeonik-bold">Enhanced Professionalism</span> Impress clients with seamless proposals and delivery.</div>  
                  <div class="mb-1"><span class="font-aeonik-bold">QuickBooks Sync</span> Effortless financial management with QuickBooks integration.</div></div>`,
          image: require("@/assets/images/app-tour/image-4.png"),
        },
        {
          title: `<div class="mb-1">Take the Stress Out of Service <br>Management</div>`,
          text: "<span>CollabDocs is your ultimate partner for a smooth, professional, and hassle-free client experience.<br/> Focus on what you do best, we’ll handle the rest.</span>",
          image: require("@/assets/images/app-tour/image-5.png"),
        },
      ],
    };
  },
};
</script>

<style>

.slide-title{
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  color: #ffffff;
  text-align: left;
  border: none;
  text-shadow: none;
  text-wrap: noWrap;
  letter-spacing: 1px;
}
.slide-sub-title{
  font-size: 16px;
  font-weight:400;
  line-height: 20px;
  color: #ffffff;
  text-align: left;
  border: none;
  text-shadow: none;
}
.slide-img{
  width: 480px;
  object-fit: cover;
  margin-bottom: 50px;
  border-radius: 10px;
}
.carousel-caption{
  left:12% !important;
}
</style>
